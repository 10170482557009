// total new user
const SET_VISITOR_DATA = (state, payload) => {
  state.tracker = payload.data
}
const SET_VISITOR_STATE = (state, payload) => {
  state.state = payload
}
// end of total new user

export default {
  SET_VISITOR_DATA,
  SET_VISITOR_STATE
}
