import mutation from './mutation'
import action from './action'
import getter from './getter'
export default {
  namespaced: true,
  state: {
    blastData: [],
    blastDataReady: false,
    blastItem: null,
    blastItemReady: false,
    newsletter: null,
    newsletterReady: false,
    marketDatas: [],
    targetedArticles: [],
    targetArticlesReady: false,
    ready: false,
    updatingNewsletter: false,
    previewReady: false,
    blastPreview: ''
  },
  getters: getter,
  mutations: mutation,
  actions: action,
  modules: {}
}
