const GET_TOTAL_PUBLISHED_ARTICLE = state => {
  return state.publishedArticles
}

const TOTAL_PUBLISHED_ARTICLE_STATE = state => {
  return state.publishedArticleState
}

export default {
  GET_TOTAL_PUBLISHED_ARTICLE,
  TOTAL_PUBLISHED_ARTICLE_STATE
}
