const GET_ARTICLE_GROUPS = state => {
  var data = []
  if (state.articleGroups.data !== undefined && state.articleGroups.data !== null) {
    state.articleGroups.data.forEach(val => {
      data.push(val)
    })
  }
  // console.log('data')
  // console.log(data)
  return data
}
const GET_ARTICLE_GROUP_TOTAL_PAGE = state => {
  return state.articleGroups.total_page
}
const SELECT_ARTICLE_GROUP = state => {
  var data = []
  if (state.articleGroups.data !== undefined && state.articleGroups.data !== null) {
    state.articleGroups.data.forEach(val => {
      data.push({ id: val.id, slider_name: val.slider_name })
    })
  }
  // console.log('data')
  // console.log(data)
  return data
}
const GET_ARTICLE_GROUP = state => {
  return state.articleGroup
}

const ARTICLE_GROUP_READY = state => {
  return state.ready
}

export default {
  GET_ARTICLE_GROUPS,
  ARTICLE_GROUP_READY,
  GET_ARTICLE_GROUP,
  SELECT_ARTICLE_GROUP,
  GET_ARTICLE_GROUP_TOTAL_PAGE
}
