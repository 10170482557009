<template>
  <!-- <perfect-scrollbar> -->
    <div id="app" class="min-h-screen w-screen flex">
      <div class="flex flex-row overflow-auto w-full">
        <TheSideNavigationBar />
        <div class="flex-auto flex-grow flex-shrink bg-background-50" id="mainContent">
          <TheContentTopNavigationBar />
          <slot></slot>
        </div>
        <TheSideCalendarBar />
      </div>
    </div>
  <!-- </perfect-scrollbar> -->
</template>

<script>
import TheSideNavigationBar from '@/components/TheSideNavigationBar.vue'
import TheContentTopNavigationBar from '@/components/TheContentTopNavigationBar.vue'
import TheSideCalendarBar from '@/components/TheSideCalendarBar.vue'

export default {
  components: {
    TheSideNavigationBar: TheSideNavigationBar,
    TheContentTopNavigationBar: TheContentTopNavigationBar,
    TheSideCalendarBar: TheSideCalendarBar
  }
}
</script>
<style>
#mainContent {
  max-width: 1300px;
}
</style>
