<template>
  <div class="px-8 bg-primary" style="width:230px">
    <h2 class="font-semibold text-headline-5 mt-12 text-white">Algo Research</h2>
    <div class="flex flex-col justify-between flex-1 mt-6" v-if="GET_STATUS_STATE"><Loading class="text-white" color="white" /></div>
    <div class="flex flex-col justify-between flex-1 mt-6">
      <nav>
        <router-link
          :to="{ name: 'route.dashboard.home' }"
          class="flex items-center px-4 py-3 mb-4 text-white rounded-md"
          active-class="flex items-center px-4 py-3 mb-4 text-white bg-white-translucent rounded-md"
          exact-path
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.07861 15.1354H13.8936"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.3999 12.713C1.3999 7.082 2.0139 7.475 5.3189 4.41C6.7649 3.246 9.0149 1 10.9579 1C12.8999 1 15.1949 3.235 16.6539 4.41C19.9589 7.475 20.5719 7.082 20.5719 12.713C20.5719 21 18.6129 21 10.9859 21C3.3589 21 1.3999 21 1.3999 12.713Z"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <span class="font-button text-button ml-8">Home</span>
        </router-link>
        <router-link
          :to="{ name: 'route.dashboard.article' }"
          class="flex items-center px-4 py-3 mb-4 text-white rounded-md"
          active-class="flex items-center px-4 py-3 mb-4 text-white bg-white-translucent rounded-md"
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 19 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.7161 14.2234H5.49609"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12.7161 10.0369H5.49609"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.25109 5.86008H5.49609"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.9085 0.749786C12.9085 0.749786 5.23149 0.753786 5.21949 0.753786C2.45949 0.770786 0.750488 2.58679 0.750488 5.35679V14.5528C0.750488 17.3368 2.47249 19.1598 5.25649 19.1598C5.25649 19.1598 12.9325 19.1568 12.9455 19.1568C15.7055 19.1398 17.4155 17.3228 17.4155 14.5528V5.35679C17.4155 2.57279 15.6925 0.749786 12.9085 0.749786Z"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <span class="font-button text-button ml-8">Research</span>
        </router-link>
        <router-link
          v-if="GET_USER_STATUS.role === 'owner' || GET_USER_STATUS.role === 'admin'|| GET_USER_STATUS.role === 'auditor'"
          :to="{ name: 'route.dashboard.articlegroup' }"
          class="flex items-center px-4 py-3 mb-4 text-white rounded-md"
          active-class="flex items-center px-4 py-3 mb-4 text-white bg-white-translucent rounded-md"
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 19 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.7161 14.2234H5.49609"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12.7161 10.0369H5.49609"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.25109 5.86008H5.49609"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.9085 0.749786C12.9085 0.749786 5.23149 0.753786 5.21949 0.753786C2.45949 0.770786 0.750488 2.58679 0.750488 5.35679V14.5528C0.750488 17.3368 2.47249 19.1598 5.25649 19.1598C5.25649 19.1598 12.9325 19.1568 12.9455 19.1568C15.7055 19.1398 17.4155 17.3228 17.4155 14.5528V5.35679C17.4155 2.57279 15.6925 0.749786 12.9085 0.749786Z"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <span class="font-button text-button ml-8">Research Group</span>
        </router-link>
        <router-link
          v-if="GET_USER_STATUS.role == 'owner' || GET_USER_STATUS.role == 'admin'|| GET_USER_STATUS.role == 'auditor'"
          :to="{ name: 'route.dashboard.articleslider' }"
          class="flex items-center px-4 py-3 mb-4 text-white rounded-md"
          active-class="flex items-center px-4 py-3 mb-4 text-white bg-white-translucent rounded-md"
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 19 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.7161 14.2234H5.49609"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12.7161 10.0369H5.49609"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.25109 5.86008H5.49609"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.9085 0.749786C12.9085 0.749786 5.23149 0.753786 5.21949 0.753786C2.45949 0.770786 0.750488 2.58679 0.750488 5.35679V14.5528C0.750488 17.3368 2.47249 19.1598 5.25649 19.1598C5.25649 19.1598 12.9325 19.1568 12.9455 19.1568C15.7055 19.1398 17.4155 17.3228 17.4155 14.5528V5.35679C17.4155 2.57279 15.6925 0.749786 12.9085 0.749786Z"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <span class="font-button text-button ml-8">Sliders</span>
        </router-link>
        <router-link
          v-if="GET_USER_STATUS.role == 'owner' || GET_USER_STATUS.role == 'admin'|| GET_USER_STATUS.role == 'auditor'"
          :to="{ name: 'route.dashboard.category' }"
          class="flex items-center px-4 py-3 mb-4 text-white rounded-md"
          active-class="flex items-center px-4 py-3 mb-4 text-white bg-white-translucent rounded-md"
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 640 512">
            <path fill="white"
            d="M128 352H32c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32v-96c0-17.67-14.33-32-32-32zm-24-80h192v48h48v-48h192v48h48v-57.59c0-21.17-17.23-38.41-38.41-38.41H344v-64h40c17.67 0 32-14.33 32-32V32c0-17.67-14.33-32-32-32H256c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h40v64H94.41C73.23 224 56 241.23 56 262.41V320h48v-48zm264 80h-96c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32v-96c0-17.67-14.33-32-32-32zm240 0h-96c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32v-96c0-17.67-14.33-32-32-32z">
            </path>
          </svg>

          <span class="font-button text-button ml-8">Category</span>
        </router-link>
        <router-link
          v-if="GET_USER_STATUS.role == 'owner' || GET_USER_STATUS.role == 'admin'|| GET_USER_STATUS.role == 'auditor'"
          :to="{ name: 'route.dashboard.mastertag' }"
          class="flex items-center px-4 py-3 mb-4 text-white rounded-md"
          active-class="flex items-center px-4 py-3 mb-4 text-white bg-white-translucent rounded-md"
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 640 512">
            <path fill="white"
            d="M128 352H32c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32v-96c0-17.67-14.33-32-32-32zm-24-80h192v48h48v-48h192v48h48v-57.59c0-21.17-17.23-38.41-38.41-38.41H344v-64h40c17.67 0 32-14.33 32-32V32c0-17.67-14.33-32-32-32H256c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h40v64H94.41C73.23 224 56 241.23 56 262.41V320h48v-48zm264 80h-96c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32v-96c0-17.67-14.33-32-32-32zm240 0h-96c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32v-96c0-17.67-14.33-32-32-32z">
            </path>
          </svg>

          <span class="font-button text-button ml-8">Master Tag</span>
        </router-link>
        <router-link
          v-if="GET_USER_STATUS.role == 'owner' || GET_USER_STATUS.role == 'admin'|| GET_USER_STATUS.role == 'auditor'"
          :to="{ name: 'route.dashboard.newsletterindex' }"
          class="flex items-center px-4 py-3 mb-4 text-white rounded-md"
          active-class="flex items-center px-4 py-3 mb-4 text-white bg-white-translucent rounded-md"
        >
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none">
          <path opacity="0.1" d="M3 12C3 4.5885 4.5885 3 12 3C19.4115 3 21 4.5885 21 12C21 19.4115 19.4115 21 12 21C4.5885 21 3 19.4115 3 12Z" fill="#ffffff"/>
          <path d="M12 10L12 16" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M16 8L16 16" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M8 11L8 16" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M3 12C3 4.5885 4.5885 3 12 3C19.4115 3 21 4.5885 21 12C21 19.4115 19.4115 21 12 21C4.5885 21 3 19.4115 3 12Z" stroke="#ffffff" stroke-width="2"/>
        </svg>

          <span class="font-button text-button ml-8">Newsletter</span>
        </router-link>
        <router-link
          v-if="GET_USER_STATUS.role == 'owner' || GET_USER_STATUS.role == 'admin'|| GET_USER_STATUS.role == 'auditor'"
          :to="{ name: 'route.dashboard.stockindex' }"
          class="flex items-center px-4 py-3 mb-4 text-white rounded-md"
          active-class="flex items-center px-4 py-3 mb-4 text-white bg-white-translucent rounded-md"
        >
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none">
          <path opacity="0.1" d="M3 12C3 4.5885 4.5885 3 12 3C19.4115 3 21 4.5885 21 12C21 19.4115 19.4115 21 12 21C4.5885 21 3 19.4115 3 12Z" fill="#ffffff"/>
          <path d="M12 10L12 16" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M16 8L16 16" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M8 11L8 16" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M3 12C3 4.5885 4.5885 3 12 3C19.4115 3 21 4.5885 21 12C21 19.4115 19.4115 21 12 21C4.5885 21 3 19.4115 3 12Z" stroke="#ffffff" stroke-width="2"/>
        </svg>

          <span class="font-button text-button ml-8">Stock Index</span>
        </router-link>
        <router-link
          v-if="GET_USER_STATUS.role == 'owner' || GET_USER_STATUS.role == 'admin'|| GET_USER_STATUS.role == 'auditor'"
          :to="{ name: 'route.dashboard.user' }"
          class="flex items-center px-4 py-3 mb-4 text-white rounded-md"
          active-class="flex items-center px-4 py-3 mb-4 text-white bg-white-translucent rounded-md"
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 16 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.98468 13.3462C4.11707 13.3462 0.814209 13.931 0.814209 16.2729C0.814209 18.6148 4.09611 19.2205 7.98468 19.2205C11.8523 19.2205 15.1542 18.6348 15.1542 16.2938C15.1542 13.9529 11.8733 13.3462 7.98468 13.3462Z"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.98464 10.0059C10.5227 10.0059 12.5799 7.94782 12.5799 5.40972C12.5799 2.87163 10.5227 0.814484 7.98464 0.814484C5.44655 0.814484 3.38845 2.87163 3.38845 5.40972C3.37988 7.93925 5.42369 9.99734 7.95226 10.0059H7.98464Z"
              stroke="white"
              stroke-width="1.42857"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

        <span class="font-button text-button ml-8">User</span>
        </router-link>
        <router-link
          v-if="GET_USER_STATUS.role == 'owner' || GET_USER_STATUS.role == 'admin'|| GET_USER_STATUS.role == 'auditor'"
          :to="{ name: 'route.dashboard.client' }"
          class="flex items-center px-4 py-3 mb-4 text-white rounded-md"
          active-class="flex items-center px-4 py-3 mb-4 text-white bg-white-translucent rounded-md"
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.59151 13.2068C11.2805 13.2068 14.4335 13.7658 14.4335 15.9988C14.4335 18.2318 11.3015 18.8068 7.59151 18.8068C3.90151 18.8068 0.749512 18.2528 0.749512 16.0188C0.749512 13.7848 3.88051 13.2068 7.59151 13.2068Z"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.59157 10.0198C5.16957 10.0198 3.20557 8.05679 3.20557 5.63479C3.20557 3.21279 5.16957 1.24979 7.59157 1.24979C10.0126 1.24979 11.9766 3.21279 11.9766 5.63479C11.9856 8.04779 10.0356 10.0108 7.62257 10.0198H7.59157Z"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14.4832 8.88159C16.0842 8.65659 17.3172 7.28259 17.3202 5.61959C17.3202 3.98059 16.1252 2.62059 14.5582 2.36359"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16.5955 12.7322C18.1465 12.9632 19.2295 13.5072 19.2295 14.6272C19.2295 15.3982 18.7195 15.8982 17.8955 16.2112"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <span class="font-button text-button ml-8">Client</span>
        </router-link>
        <router-link
          v-if="GET_USER_STATUS.role == 'owner' || GET_USER_STATUS.role == 'admin'|| GET_USER_STATUS.role == 'auditor'"
          :to="{ name: 'route.dashboard.articleblast' }"
          class="flex items-center px-4 py-3 mb-4 text-white rounded-md"
          active-class="flex items-center px-4 py-3 mb-4 text-white bg-white-translucent rounded-md"
        >
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none">
          <path opacity="0.1" d="M3 12C3 4.5885 4.5885 3 12 3C19.4115 3 21 4.5885 21 12C21 19.4115 19.4115 21 12 21C4.5885 21 3 19.4115 3 12Z" fill="#ffffff"/>
          <path d="M12 10L12 16" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M16 8L16 16" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M8 11L8 16" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M3 12C3 4.5885 4.5885 3 12 3C19.4115 3 21 4.5885 21 12C21 19.4115 19.4115 21 12 21C4.5885 21 3 19.4115 3 12Z" stroke="#ffffff" stroke-width="2"/>
        </svg>

          <span class="font-button text-button ml-8">Article Blast</span>
        </router-link>
        <router-link
          v-if="GET_USER_STATUS.role == 'owner' || GET_USER_STATUS.role == 'admin'|| GET_USER_STATUS.role == 'auditor'"
          :to="{ name: 'route.dashboard.litbang' }"
          class="flex items-center px-4 py-3 mb-4 text-white rounded-md"
          active-class="flex items-center px-4 py-3 mb-4 text-white bg-white-translucent rounded-md"
        >
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none">
          <path opacity="0.1" d="M3 12C3 4.5885 4.5885 3 12 3C19.4115 3 21 4.5885 21 12C21 19.4115 19.4115 21 12 21C4.5885 21 3 19.4115 3 12Z" fill="#ffffff"/>
          <path d="M12 10L12 16" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M16 8L16 16" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M8 11L8 16" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M3 12C3 4.5885 4.5885 3 12 3C19.4115 3 21 4.5885 21 12C21 19.4115 19.4115 21 12 21C4.5885 21 3 19.4115 3 12Z" stroke="#ffffff" stroke-width="2"/>
        </svg>

          <span class="font-button text-button ml-8">Pemilu 2024</span>
        </router-link>
        <!-- <router-link
          :to="{ name: 'route.dashboard.subscription' }"
          class="flex items-center px-4 py-3 mb-4 text-white rounded-md"
          active-class="flex items-center px-4 py-3 mb-4 text-white bg-white-translucent rounded-md"
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.8877 10.8967C19.2827 10.7007 20.3567 9.50467 20.3597 8.05567C20.3597 6.62767 19.3187 5.44367 17.9537 5.21967"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M19.7285 14.2503C21.0795 14.4523 22.0225 14.9253 22.0225 15.9003C22.0225 16.5713 21.5785 17.0073 20.8605 17.2813"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.8867 14.6638C8.67273 14.6638 5.92773 15.1508 5.92773 17.0958C5.92773 19.0398 8.65573 19.5408 11.8867 19.5408C15.1007 19.5408 17.8447 19.0588 17.8447 17.1128C17.8447 15.1668 15.1177 14.6638 11.8867 14.6638Z"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.8869 11.8879C13.9959 11.8879 15.7059 10.1789 15.7059 8.06888C15.7059 5.95988 13.9959 4.24988 11.8869 4.24988C9.7779 4.24988 8.0679 5.95988 8.0679 8.06888C8.0599 10.1709 9.7569 11.8809 11.8589 11.8879H11.8869Z"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5.88484 10.8967C4.48884 10.7007 3.41584 9.50467 3.41284 8.05567C3.41284 6.62767 4.45384 5.44367 5.81884 5.21967"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4.044 14.2503C2.693 14.4523 1.75 14.9253 1.75 15.9003C1.75 16.5713 2.194 17.0073 2.912 17.2813"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <span class="font-button text-button ml-8">Subscription</span>
        </router-link> -->
        <!-- <router-link
          :to="{ name: 'route.dashboard.discount' }"
          class="flex items-center px-4 py-3 mb-4 text-white rounded-md"
          active-class="flex items-center px-4 py-3 mb-4 text-white bg-white-translucent rounded-md"
        >
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.79501 5.05601C2.79501 3.80701 3.80701 2.79501 5.05601 2.79401H6.08501C6.68201 2.79401 7.25401 2.55701 7.67801 2.13701L8.39701 1.41701C9.27801 0.531012 10.71 0.527012 11.596 1.40801L11.597 1.40901L11.606 1.41701L12.326 2.13701C12.75 2.55801 13.322 2.79401 13.919 2.79401H14.947C16.196 2.79401 17.209 3.80601 17.209 5.05601V6.08301C17.209 6.68001 17.445 7.25301 17.866 7.67701L18.586 8.39701C19.472 9.27801 19.477 10.71 18.596 11.596L18.595 11.597L18.586 11.606L17.866 12.326C17.445 12.749 17.209 13.321 17.209 13.918V14.947C17.209 16.196 16.197 17.208 14.948 17.208H14.947H13.917C13.32 17.208 12.747 17.445 12.324 17.866L11.604 18.585C10.724 19.471 9.29301 19.476 8.40701 18.597C8.40601 18.596 8.40501 18.595 8.40401 18.594L8.39501 18.585L7.67601 17.866C7.25301 17.445 6.68001 17.209 6.08301 17.208H5.05601C3.80701 17.208 2.79501 16.196 2.79501 14.947V13.916C2.79501 13.319 2.55801 12.747 2.13701 12.324L1.41801 11.604C0.532008 10.724 0.527008 9.29301 1.40701 8.40701C1.40701 8.40601 1.40801 8.40501 1.40901 8.40401L1.41801 8.39501L2.13701 7.67501C2.55801 7.25101 2.79501 6.67901 2.79501 6.08101V5.05601"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7.43164 12.5717L12.5716 7.4317"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12.4955 12.5H12.5045"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7.4955 7.5H7.5045"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <span class="font-button text-button ml-8">Discount</span>
        </router-link> -->
        <!-- <router-link
          :to="{ name: 'route.dashboard.transaction' }"
          class="flex items-center px-4 py-3 mb-4 text-white rounded-md"
          active-class="flex items-center px-4 py-3 mb-4 text-white bg-white-translucent rounded-md"
        >
          <svg
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.8395 17.1642V3.54645"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M18.9173 13.0681L14.8395 17.1648L10.7617 13.0681"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4.91127 0.832886V14.4507"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M0.833496 4.929L4.91127 0.832336L8.98905 4.929"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <span class="font-button text-button ml-8">Transaction</span>
        </router-link> -->
      </nav>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import Loading from '@/components/Loading.vue'
const internalUser = createNamespacedHelpers('internalUser')
export default {
  components: {
    Loading
  },
  computed: {
    ...internalUser.mapGetters(['GET_USER_STATUS', 'GET_STATUS_STATE'])
  },
  methods: {
    ...internalUser.mapActions(['getStatus', 'createInit'])
  },
  mounted () {
    this.createInit()
    this.getStatus()
  }
}
</script>
