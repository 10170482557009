<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
// import { Quill } from 'vue2-editor'
export default {
  name: 'App',
  computed: {
    layout () {
      return (this.$route.meta.layout || 'blank') + '-layout'
    }
  }
}
</script>
<style>
body {
  width: 100%;
  overflow-x: hidden;
}
</style>
