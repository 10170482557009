const SET_PROFILE = (state, payload) => {
  state.profile = payload.data
}
const SET_PROFILE_STATE = (state, payload) => {
  state.ready = payload
}

export default {
  SET_PROFILE,
  SET_PROFILE_STATE
}
