
const SET_ARTICLE = (state, payload) => {
  if (payload.data !== null) {
    state.translatedArticle = payload.data
  }
}
const SET_ARTICLE_STATE = (state, payload) => {
  state.ready = payload
}
const SET_TRANSLATED_THUMBNAIL = (state, payload) => {
  state.translatedArticle.thumbnail = payload
}

export default {
  SET_ARTICLE,
  SET_ARTICLE_STATE,
  SET_TRANSLATED_THUMBNAIL
}
