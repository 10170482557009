const SET_NEWSLETTER = (state, payload) => {
  state.newsletter = payload
}
const SET_BLASTDATA = (state, payload) => {
  state.blastData = payload
}
const SET_BLASTDATA_READY = (state, payload) => {
  state.blastDataReady = payload
}
const SET_BLASTITEM = (state, payload) => {
  state.blastItem = payload
}
const SET_BLASTITEM_READY = (state, payload) => {
  state.blastItemReady = payload
}
const SET_TARGET_ARTICLES = (state, payload) => {
  state.targetedArticles = payload.data
}
const SET_BLAST_PREVIEW = (state, payload) => {
  state.blastPreview = payload.view
}
const SET_NEWSLETTER_READY = (state, payload) => {
  state.newsletterReady = payload
}
const SET_UPDATE_STATE = (state, payload) => {
  state.updatingNewsletter = payload
}
const SET_PREVIEW_STATE = (state, payload) => {
  state.previewReady = payload
}
const SET_TARGET_ARTICLE_STATE = (state, payload) => {
  state.targetArticlesReady = payload
}

export default {
  SET_NEWSLETTER,
  SET_NEWSLETTER_READY,
  SET_UPDATE_STATE,
  SET_BLASTDATA,
  SET_BLASTDATA_READY,
  SET_BLASTITEM,
  SET_BLASTITEM_READY,
  SET_TARGET_ARTICLE_STATE,
  SET_TARGET_ARTICLES,
  SET_BLAST_PREVIEW,
  SET_PREVIEW_STATE
}
