const GET_PROFILE = state => {
  return state.profile
}
const PROFILE_STATE = state => {
  return state.ready
}

export default {
  GET_PROFILE,
  PROFILE_STATE
}
