<template>
  <div class="inline-flex my-2" v-show="show">
    <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 28 28">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="gray" stroke-width="4"></circle>
      <path class="opacity-75" :fill="color" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
    Please wait
  </div>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    color: {
      type: String,
      default: 'black'
    }
  }
}
</script>
