<template>
  <div class="w-104 min-w-0 bg-white" style="min-width:395px;max-width:395px" id="sideCalendarBar">
    <div class="px-12 mx-auto mt-12 flex justify-between items-center">
      <div class="flex items-center justify-between">
        <div>
          <h3 class="font-headline-6 text-headline-6">Date</h3>
        </div>
      </div>
    </div>
    <div class="mt-12 mx-2 px-4 flex side-calendar" v-show="showButtonToggle">
      <ButtonSolidBox class="px-6 py-0 rounded-full" :color="dateDefaultState ? 'red' : 'primary'" @click="toggleDate()">
        <small>{{ dateDefaultState ? 'Disable date filter' : 'Activate date filter' }}</small>
      </ButtonSolidBox>
    </div>
    <div class="mt-6 mx-2 px-4 flex side-calendar relative">
      <div style="position:absolute;top:0;bottom:0;left:0;right:0;z-index:10" v-if="!dateDefaultState"></div>
      <v-date-picker
        is-range
        v-model="rangeModel"
        class="border-1 border-gray-100 w-96 h-96 mx-auto bg-blue-accent-translucent"
        :attributes="attributes"
      >
        <!-- <template v-slot:header-left-button>
          <span> prev </span>
        </template>
        <template v-slot:header-right-button>
          <span> next </span>
        </template> -->
        <!-- <template v-slot:day-content="{ day }">
          <div class="flex flex-col h-full text-center py-3">
            <span class="day-label font-button text-button">{{ day.day }}</span>
          </div>
        </template> -->
      </v-date-picker>
    </div>
  </div>
</template>

<script>
import ButtonSolidBox from '@/components/Buttons/ButtonSolidBox.vue'
export default {
  components: {
    ButtonSolidBox
  },
  data () {
    return {
      attributes: [
        {
          // key: 'today',
          // highlight: {
          //   class: 'p-4 bg-blue-accent'
          // },
          // dates: new Date()
        }
      ],
      rangeModel: {
        start: '',
        end: ''
      }
    }
  },
  watch: {
    rangeModel: function (newVal) {
      this.$emit('date-updated', newVal)
    }
  },
  props: {
    hideToday: {
      type: Boolean,
      default () {
        return false
      }
    },
    showButtonToggle: {
      type: Boolean,
      default () {
        return false
      }
    },
    dateDefaultState: {
      type: Boolean,
      default () {
        return true
      }
    },
    range: {
      start: {
        type: String,
        default () {
          return new Date()
        }
      },
      end: {
        type: String,
        default () {
          return new Date()
        }
      }
    }
  },
  created () {
    this.rangeModel = this.range
  },
  methods: {
    toggleDate () {
      this.$emit('date-state-changed', !this.dateDefaultState)
    }
  }
}
</script>

<style scoped>
.side-calendar >>> .vc-container {
  @apply font-sans text-gray-500 border-gray-100;
  width: -webkit-fill-available !important;
  width:100% !important;
  background-color: rgba(18,44,145,.01);
}
.side-calendar >>> .vc-title {
  @apply mt-3 py-4 font-subtitle-2 text-subtitle-1;
}
.side-calendar >>> .vc-weeks .vc-weekday {
  @apply py-3 font-button text-button text-gray;
}
.side-calendar >>> .vc-day.is-today .day-label {
  @apply text-white;
}
.side-calendar >>> .vc-day.is-not-in-month:not(.on-bottom) * {
  @apply opacity-100;
}</style>
