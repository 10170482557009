import request from '@/utils/axios'

// const urlGetTransform = function (baseurl, payload) {
//   var url = baseurl
//   if (payload !== null) {
//     url += '&page=' + payload.page ? payload.page : '1'
//     url += '&start_date=' + payload.range.start
//     url += '&end_date=' + payload.range.end
//     url += '&filter_target=' + payload.filter.target
//     url += '&filter_sort=' + payload.filter.sort
//   }
//   return url
// }

export default {
  createInit: ({ commit }) => {
    commit('SET_RESEARCH_TAG_STATE', true)
  },
  getResearchTags: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      commit('SET_RESEARCH_TAG_STATE', false)
      var url = `/v2/research/internal/research-tags/${id}`
      request().get(url)
        .then((result) => {
          commit('SET_RESEARCH_TAGS', result.data)
          resolve(result.data)
          commit('SET_RESEARCH_TAG_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_RESEARCH_TAG_STATE', true)
        })
    })
  },
  addNewTagByIdToResearch: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      commit('SET_RESEARCH_TAG_STATE', false)
      var url = '/v2/research/internal/research-tags/byId'
      request().post(url, data)
        .then((result) => {
          // commit('SET_RESEARCH_TAGS', result.data)
          resolve()
          commit('SET_RESEARCH_TAG_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_RESEARCH_TAG_STATE', true)
        })
    })
  },
  addNewTagByNameToResearch: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      commit('SET_RESEARCH_TAG_STATE', false)
      var url = '/v2/research/internal/research-tags/byTagName'
      request().post(url, data)
        .then((result) => {
          // commit('SET_RESEARCH_TAGS', result.data)
          resolve()
          commit('SET_RESEARCH_TAG_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_RESEARCH_TAG_STATE', true)
        })
    })
  },
  removeResearchTag: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      commit('SET_RESEARCH_TAG_STATE', false)
      var url = `/v2/research/internal/research-tags/${id}`
      request().delete(url)
        .then((result) => {
          // commit('SET_RESEARCH_TAGS', result.data)
          resolve()
          commit('SET_RESEARCH_TAG_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_RESEARCH_TAG_STATE', true)
        })
    })
  }
}
