<template>
  <!-- <perfect-scrollbar> -->
    <div id="app" class="min-h-screen w-screen flex">
      <slot></slot>
    </div>
  <!-- </perfect-scrollbar> -->
</template>

<script>
// import TheContentTopNavigationBar from '@/components/TheContentTopNavigationBar.vue'
// import TheSideCalendarBar from '@/components/TheSideCalendarBar.vue'

export default {
  components: {
    // TheContentTopNavigationBar: TheContentTopNavigationBar,
    // TheSideCalendarBar: TheSideCalendarBar
  }
}
</script>
