// total new user
const SET_TOTAL_PUBLISHED_ARTICLE = (state, payload) => {
  state.publishedArticles = payload.data
}
const SET_TOTAL_PUBLISHED_ARTICLE_STATE = (state, payload) => {
  state.publishedArticleState = payload
}
// end of total new user

export default {
  SET_TOTAL_PUBLISHED_ARTICLE,
  SET_TOTAL_PUBLISHED_ARTICLE_STATE
}
