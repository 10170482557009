const GET_INTERNAL_USERS = state => {
  var data = []
  if (state.internal_users.data !== undefined) {
    state.internal_users.data.forEach(val => {
      if (val.profile === undefined) {
        val.profile = 0
      }
      data.push(val)
    })
  }
  return data
}

const GET_INTERNAL_USER_TOTAL_PAGE = state => {
  return state.internal_users.total_page
}

const GET_USER_STATUS = state => {
  return state.status
}
const GET_STATUS_STATE = state => {
  return state.statusState
}

const GET_INTERNAL_USER = state => {
  return state.internal_user
}

const INTERNAL_USER_READY = state => {
  return state.ready
}

export default {
  GET_USER_STATUS,
  GET_INTERNAL_USERS,
  INTERNAL_USER_READY,
  GET_INTERNAL_USER,
  GET_STATUS_STATE,
  GET_INTERNAL_USER_TOTAL_PAGE
}
