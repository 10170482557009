const GET_RESEARCH_TAGS = state => {
  var data = []
  if (state.tags !== undefined) {
    state.tags.forEach(val => {
      data.push(val)
    })
  }
  // console.log('data')
  // console.log(data)
  return data
}

const RESEARCH_TAG_READY = state => {
  return state.ready
}

export default {
  GET_RESEARCH_TAGS,
  RESEARCH_TAG_READY
}
