const GET_NEWSLETTERS = state => {
  var data = []
  if (state.newsletters !== undefined && state.newsletters !== null) {
    state.newsletters.forEach(val => {
      data.push(val)
    })
  }
  // console.log('data')
  // console.log(data)
  return data
}
const GET_BLASTDATA = state => {
  return state.blastData
}
const BLASTDATA_READY = state => {
  return state.blastDataReady
}
const GET_BLASTITEM = state => {
  return state.blastItem
}
const BLASTITEM_READY = state => {
  return state.blastItemReady
}
const GET_ARTICLEBLAST_PREVIEW = state => {
  return state.blastPreview
}
const GET_PREVIEW_READY = state => {
  return state.previewReady
}
const GET_NEWSLETTER_ARTICLES = state => {
  return state.targetedArticles
}
const TARGET_ARTICLE_STATE = state => {
  return state.targetArticlesReady
}

const GET_UPDATE_STATE = state => {
  return state.updatingNewsletter
}
const NEWSLETTERS_READY = state => {
  return state.newslettersReady
}

export default {
  GET_BLASTDATA,
  BLASTDATA_READY,
  GET_BLASTITEM,
  BLASTITEM_READY,
  GET_NEWSLETTERS,
  NEWSLETTERS_READY,
  GET_UPDATE_STATE,
  GET_NEWSLETTER_ARTICLES,
  TARGET_ARTICLE_STATE,
  GET_ARTICLEBLAST_PREVIEW,
  GET_PREVIEW_READY
}
