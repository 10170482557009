import request from '@/utils/axios'

export default {
  getPublishedArticle: ({ commit }, { filter }) => {
    return new Promise((resolve, reject) => {
      commit('SET_TOTAL_PUBLISHED_ARTICLE_STATE', false)
      var url = '/v1/statistic/article/published'
      if (filter.start_date !== undefined && filter.end_date !== undefined) {
        url += '?start_date=' + filter.start_date + '&end_date=' + filter.end_date
      }
      request().get(url)
        .then((result) => {
          commit('SET_TOTAL_PUBLISHED_ARTICLE', result.data)
          resolve(result.data)
          commit('SET_TOTAL_PUBLISHED_ARTICLE_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_TOTAL_PUBLISHED_ARTICLE_STATE', true)
        })
    })
  }
}
