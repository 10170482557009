import request from '@/utils/axios'

// const urlGetTransform = function (baseurl, payload) {
//   var url = baseurl
//   if (payload !== null) {
//     url += '&page=' + payload.page ? payload.page : '1'
//     url += '&start_date=' + payload.range.start
//     url += '&end_date=' + payload.range.end
//     url += '&filter_target=' + payload.filter.target
//     url += '&filter_sort=' + payload.filter.sort
//   }
//   return url
// }

export default {
  createInit: ({ commit }) => {
    commit('SET_TAG_STATE', true)
  },
  getAllTags: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit('SET_TAG_STATE', false)
      var url = '/v2/research/internal/master-tags'
      request().get(url)
        .then((result) => {
          commit('SET_TAGS', result.data)
          resolve(result.data)
          commit('SET_TAG_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_TAG_STATE', true)
        })
    })
  },
  getAllDeletedTags: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit('SET_TAG_STATE', false)
      var url = '/v2/research/internal/master-tags/deleted'
      request().get(url)
        .then((result) => {
          commit('SET_DELETED_TAGS', result.data)
          resolve(result.data)
          commit('SET_TAG_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_TAG_STATE', true)
        })
    })
  },
  storeNewTag: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      commit('SET_TAG_STATE', false)
      var url = '/v2/research/internal/master-tags'
      request().post(url, data)
        .then((result) => {
          // commit('SET_TAGS', result.data)
          resolve()
          commit('SET_TAG_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_TAG_STATE', true)
        })
    })
  },
  removeTag: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      commit('SET_TAG_STATE', false)
      var url = `/v2/research/internal/master-tags/${data.id}`
      request().delete(url)
        .then((result) => {
          // commit('SET_TAGS', result.data)
          resolve()
          commit('SET_TAG_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_TAG_STATE', true)
        })
    })
  },
  restoreTag: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      commit('SET_TAG_STATE', false)
      var url = `/v2/research/internal/master-tags/restore/${data.id}`
      request().put(url)
        .then((result) => {
          // commit('SET_TAGS', result.data)
          resolve()
          commit('SET_TAG_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_TAG_STATE', true)
        })
    })
  }
  // getCategories: ({ commit }, { data }) => {
  //   return new Promise((resolve, reject) => {
  //     commit('SET_CATEGORY_STATE', false)
  //     var url = urlGetTransform('/v1/article-category?limit=10', data)
  //     request().get(url)
  //       .then((result) => {
  //         commit('SET_CATEGORIES', result.data)
  //         resolve(result.data)
  //         commit('SET_CATEGORY_STATE', true)
  //       })
  //       .catch((err) => {
  //       // console.log(err)
  //         reject(err)
  //         commit('SET_CATEGORY_STATE', true)
  //       })
  //   })
  // },
  // findCategories: ({ commit }, { data }) => {
  //   return new Promise((resolve, reject) => {
  //     commit('SET_CATEGORY_STATE', false)
  //     request().post('/v1/article-category/find', data)
  //       .then((result) => {
  //         commit('SET_CATEGORIES', result.data)
  //         resolve(result.data)
  //         commit('SET_CATEGORY_STATE', true)
  //       })
  //       .catch((err) => {
  //       // console.log(err)
  //         reject(err)
  //         commit('SET_CATEGORY_STATE', true)
  //       })
  //   })
  // },
  // getCategoryById: ({ commit }, { id }) => {
  //   return new Promise((resolve, reject) => {
  //     commit('SET_CATEGORY_STATE', false)
  //     request().get('/v1/article-category/show/' + id)
  //       .then((result) => {
  //         commit('SET_CATEGORY', result.data)
  //         resolve(result.data)
  //         commit('SET_CATEGORY_STATE', true)
  //       })
  //       .catch((err) => {
  //       // console.log(err)
  //         reject(err)
  //         commit('SET_CATEGORY_STATE', true)
  //       })
  //   })
  // },
  // addCategory: ({ commit }, { form }) => {
  //   return new Promise((resolve, reject) => {
  //     commit('SET_CATEGORY_STATE', false)
  //     request().post('/v1/article-category/create', form)
  //       .then((result) => {
  //         // commit('SET_CATEGOORIES', result.data)
  //         resolve(result.data)
  //         commit('SET_CATEGORY_STATE', true)
  //       })
  //       .catch((err) => {
  //       // console.log(err)
  //         reject(err)
  //         commit('SET_CATEGORY_STATE', true)
  //       })
  //   })
  // },
  // updateCategory: ({ commit }, { form }) => {
  //   return new Promise((resolve, reject) => {
  //     commit('SET_CATEGORY_STATE', false)
  //     request().put('/v1/article-category/update/' + form.id, form)
  //       .then((result) => {
  //         // commit('SET_CATEGOORIES', result.data)
  //         resolve(result.data)
  //         commit('SET_CATEGORY_STATE', true)
  //       })
  //       .catch((err) => {
  //       // console.log(err)
  //         reject(err)
  //         commit('SET_CATEGORY_STATE', true)
  //       })
  //   })
  // }
}
