<template>
  <button
    class="font-button text-button text-white p-3 rounded"
    :class="getBackgroundColor()"
    @click="handleClick()"
  >
    {{ text }}
    <slot v-if="text.length < 1"></slot>
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: () => {
        return ''
      }
    },
    color: {
      type: String,
      default: () => {
        return 'secondary'
      }
    }
  },
  methods: {
    getBackgroundColor () {
      return 'bg-' + this.color
    },
    handleClick () {
      this.$emit('click')
    }
  }
}
</script>
