import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'route.root',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'route.login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/reset-password',
    name: 'route.reset',
    component: () =>
      import(/* webpackChunkName: "author" */ '@/views/resetPassword.vue')
    // meta: {
    //   layout: 'landing'
    // }
  },
  {
    path: '/forgot-password',
    name: 'route.login.password-recovery',
    component: () => import(/* webpackChunkName: "password-recovery" */ '../views/PasswordRecovery.vue')
  },
  {
    path: '/dashboard',
    name: 'route.dashboard.home',
    component: () => import(/* webpackChunkName: "password-recovery" */ '../views/Dashboard/Dashboard.vue'),
    meta: {
      label: 'Dashboard',
      layout: 'dashboard'
    }
  },
  {
    path: '/dashboard/profile',
    name: 'route.dashboard.profile',
    component: () => import(/* webpackChunkName: "article-list" */ '../views/Dashboard/UserProfile/Editor.vue'),
    meta: {
      label: 'User profile',
      layout: 'dashboard-flexible'
    }
  },
  {
    path: '/dashboard/article',
    name: 'route.dashboard.article',
    component: () => import(/* webpackChunkName: "article-list" */ '../views/Dashboard/Article/ArticleList.vue'),
    meta: {
      label: 'Article',
      layout: 'dashboard-flexible'
    }
  },
  {
    path: '/dashboard/article/create',
    name: 'route.dashboard.article.editor.create',
    component: () => import(/* webpackChunkName: "article-editor" */ '../views/Dashboard/Article/ArticleEditor.vue'),
    meta: {
      label: 'Article',
      layout: 'dashboard-flexible',
      useBackButton: true
    }
  },
  {
    path: '/dashboard/article/:id/detail',
    name: 'route.dashboard.article.editor.existing',
    component: () => import(/* webpackChunkName: "article-editor" */ '../views/Dashboard/Article/ExistingEditor.vue'),
    meta: {
      label: 'Article',
      layout: 'dashboard-flexible',
      useBackButton: true
    }
  },
  {
    path: '/dashboard/article-group',
    name: 'route.dashboard.articlegroup',
    component: () => import(/* webpackChunkName: "article-list" */ '../views/Dashboard/ArticleGroup/List.vue'),
    meta: {
      label: 'Article Group',
      layout: 'dashboard-flexible'
    }
  },
  {
    path: '/dashboard/article-group/create',
    name: 'route.dashboard.articlegroup.editor.create',
    component: () => import(/* webpackChunkName: "article-editor" */ '../views/Dashboard/ArticleGroup/Editor.vue'),
    meta: {
      label: 'Article Group',
      layout: 'dashboard-flexible',
      useBackButton: true
    }
  },
  {
    path: '/dashboard/article-group/:id/detail',
    name: 'route.dashboard.articlegroup.editor.existing',
    component: () => import(/* webpackChunkName: "article-editor" */ '../views/Dashboard/ArticleGroup/ExistingEditor.vue'),
    meta: {
      label: 'Article Group',
      layout: 'dashboard-flexible',
      useBackButton: true
    }
  },
  {
    path: '/dashboard/article-slider',
    name: 'route.dashboard.articleslider',
    component: () => import(/* webpackChunkName: "article-list" */ '../views/Dashboard/ArticleSlider/List.vue'),
    meta: {
      label: 'Article Slider',
      layout: 'dashboard-flexible'
    }
  },
  {
    path: '/dashboard/article-slider/create',
    name: 'route.dashboard.articleslider.editor.create',
    component: () => import(/* webpackChunkName: "article-editor" */ '../views/Dashboard/ArticleSlider/Editor.vue'),
    meta: {
      label: 'Article Slider',
      layout: 'dashboard-flexible',
      useBackButton: true
    }
  },
  {
    path: '/dashboard/article-slider/:id/detail',
    name: 'route.dashboard.articleslider.editor.existing',
    component: () => import(/* webpackChunkName: "article-editor" */ '../views/Dashboard/ArticleSlider/ExistingEditor.vue'),
    meta: {
      label: 'Article Slider',
      layout: 'dashboard-flexible',
      useBackButton: true
    }
  },
  {
    path: '/dashboard/newsletter-index',
    name: 'route.dashboard.newsletterindex',
    component: () => import(/* webpackChunkName: "article-list" */ '../views/Dashboard/NewsLetter/List.vue'),
    meta: {
      label: 'Newsletter',
      layout: 'dashboard-flexible'
    }
  },
  {
    path: '/dashboard/newsletter-create',
    name: 'route.dashboard.newsletter.editor.create',
    component: () => import(/* webpackChunkName: "article-list" */ '../views/Dashboard/NewsLetter/NewEditor.vue'),
    meta: {
      label: 'Update Current Newsletter',
      layout: 'dashboard-flexible'
    }
  },
  {
    path: '/dashboard/stock-index',
    name: 'route.dashboard.stockindex',
    component: () => import(/* webpackChunkName: "article-list" */ '../views/Dashboard/StockIndex/IndexList.vue'),
    meta: {
      label: 'Stock Index',
      layout: 'dashboard-flexible'
    }
  },
  // {
  //   path: '/dashboard/article/:slug',
  //   name: 'route.dashboard.article.viewer.short',
  //   redirect: '/dashboard/article/:slug/view'
  // },
  // {
  //   path: '/dashboard/article/:slug/view',
  //   name: 'route.dashboard.article.viewer',
  //   component: () => import(/* webpackChunkName: "article-viewer" */ '../views/Dashboard/Article/ExistingEditor.vue'),
  //   meta: {
  //     label: 'Article',
  //     layout: 'dashboard',
  //     useBackButton: true
  //   }
  // },
  {
    path: '/dashboard/category/',
    name: 'route.dashboard.category',
    component: () => import(/* webpackChunkName: "user-list" */ '../views/Dashboard/Category/List.vue'),
    meta: {
      label: 'Category',
      layout: 'dashboard-flexible'
    }
  },
  {
    path: '/dashboard/category/create',
    name: 'route.dashboard.category.editor.create',
    component: () => import(/* webpackChunkName: "user-editor" */ '../views/Dashboard/Category/NewEditor.vue'),
    meta: {
      label: 'Category',
      layout: 'dashboard-flexible',
      useBackButton: true
    }
  },
  {
    path: '/dashboard/category/:id/detail',
    name: 'route.dashboard.category.editor.detail',
    component: () => import(/* webpackChunkName: "user-editor" */ '../views/Dashboard/Category/ExistingEditor.vue'),
    meta: {
      label: 'Category',
      layout: 'dashboard-flexible',
      useBackButton: true
    }
  },
  {
    path: '/dashboard/mastertag/',
    name: 'route.dashboard.mastertag',
    component: () => import(/* webpackChunkName: "user-list" */ '../views/Dashboard/MasterTag/List.vue'),
    meta: {
      label: 'Master Tags',
      layout: 'dashboard-flexible'
    }
  // },
  // {
  //   path: '/dashboard/mastertag/create',
  //   name: 'route.dashboard.mastertag.editor.create',
  //   component: () => import(/* webpackChunkName: "user-editor" */ '../views/Dashboard/mastertag/NewEditor.vue'),
  //   meta: {
  //     label: 'mastertag',
  //     layout: 'dashboard-flexible',
  //     useBackButton: true
  //   }
  // },
  // {
  //   path: '/dashboard/mastertag/:id/detail',
  //   name: 'route.dashboard.mastertag.editor.detail',
  //   component: () => import(/* webpackChunkName: "user-editor" */ '../views/Dashboard/mastertag/ExistingEditor.vue'),
  //   meta: {
  //     label: 'mastertag',
  //     layout: 'dashboard-flexible',
  //     useBackButton: true
  //   }
  },
  {
    path: '/dashboard/user',
    name: 'route.dashboard.user',
    component: () => import(/* webpackChunkName: "user-list" */ '../views/Dashboard/InternalUser/UserList.vue'),
    meta: {
      label: 'User',
      layout: 'dashboard-flexible'
    }
  },
  {
    path: '/dashboard/user/create',
    name: 'route.dashboard.user.editor.create',
    component: () => import(/* webpackChunkName: "user-editor" */ '../views/Dashboard/InternalUser/NewUserEditor.vue'),
    meta: {
      label: 'User',
      layout: 'dashboard-flexible',
      useBackButton: true
    }
  },
  {
    path: '/dashboard/user/:id/detail',
    name: 'route.dashboard.user.editor.detail',
    component: () => import(/* webpackChunkName: "user-editor" */ '../views/Dashboard/InternalUser/ExistingUserEditor.vue'),
    meta: {
      label: 'User',
      layout: 'dashboard-flexible',
      useBackButton: true
    }
  },
  {
    path: '/dashboard/client',
    name: 'route.dashboard.client',
    component: () => import(/* webpackChunkName: "client-list" */ '../views/Dashboard/Client/UserList.vue'),
    meta: {
      label: 'Client',
      layout: 'dashboard-flexible'
    }
  },
  {
    path: '/dashboard/client/:id/detail',
    name: 'route.dashboard.client.editor.detail',
    component: () => import(/* webpackChunkName: "user-editor" */ '../views/Dashboard/Client/ExistingUserEditor.vue'),
    meta: {
      label: 'User',
      layout: 'dashboard-flexible',
      useBackButton: true
    }
  },
  {
    path: '/dashboard/client/:username',
    name: 'route.dashboard.client.overview.short',
    redirect: '/dashboard/client/:username/overview'
  },
  {
    path: '/dashboard/client/:username/overview',
    name: 'route.dashboard.client.overview',
    component: () => import(/* webpackChunkName: "client-overview" */ '../views/Dashboard/ClientOverview.vue'),
    meta: {
      label: 'Client',
      layout: 'dashboard',
      useBackButton: true
    }
  },
  {
    path: '/dashboard/subscription',
    name: 'route.dashboard.subscription',
    component: () => import(/* webpackChunkName: "subscription-list" */ '../views/Dashboard/SubscriptionList.vue'),
    meta: {
      label: 'Subscription',
      layout: 'dashboard'
    }
  },
  {
    path: '/dashboard/subscription/create',
    name: 'route.dashboard.subscription.editor.create',
    component: () => import(/* webpackChunkName: "subscription-editor" */ '../views/Dashboard/SubscriptionEditor.vue'),
    meta: {
      label: 'Subscription',
      layout: 'dashboard',
      useBackButton: true
    }
  },
  {
    path: '/dashboard/subscription/:id',
    name: 'route.dashboard.subscription.overview.short',
    redirect: '/dashboard/subscription/:id/overview'
  },
  {
    path: '/dashboard/subscription/:id/overview',
    name: 'route.dashboard.subscription.overview',
    component: () => import(/* webpackChunkName: "client-overview" */ '../views/Dashboard/SubscriptionEditor.vue'),
    meta: {
      label: 'Subscription',
      layout: 'dashboard',
      useBackButton: true
    }
  },
  {
    path: '/dashboard/subscription/:id/edit',
    name: 'route.dashboard.subscription.editor.edit',
    component: () => import(/* webpackChunkName: "subscription-editor" */ '../views/Dashboard/SubscriptionEditor.vue'),
    meta: {
      label: 'Subscription',
      layout: 'dashboard',
      useBackButton: true
    }
  },
  {
    path: '/dashboard/discount',
    name: 'route.dashboard.discount',
    meta: {
      label: 'Discount',
      layout: 'dashboard'
    }
  },
  {
    path: '/dashboard/transaction',
    name: 'route.dashboard.transaction',
    component: () => import(/* webpackChunkName: "transaction-list" */ '../views/Dashboard/TransactionList.vue'),
    meta: {
      label: 'Transaction',
      layout: 'dashboard'
    }
  },
  {
    path: '/dashboard/transaction/:slug',
    name: 'route.dashboard.transaction.overview.short',
    redirect: '/dashboard/transaction/:slug/overview'
  },
  {
    path: '/dashboard/transaction/:slug/overview',
    name: 'route.dashboard.transaction.overview',
    component: () => import(/* webpackChunkName: "transaction-overview" */ '../views/Dashboard/TransactionOverview.vue'),
    meta: {
      label: 'Transaction',
      layout: 'dashboard',
      useBackButton: true
    }
  },
  {
    path: '/dashboard/article-blast',
    name: 'route.dashboard.articleblast',
    component: () => import(/* webpackChunkName: "article-list" */ '../views/Dashboard/ArticleBlast/BlastList.vue'),
    meta: {
      label: 'New Article Email Blast',
      layout: 'dashboard-flexible'
    }
  },
  {
    path: '/dashboard/article-blast/update/:id/detail',
    name: 'route.dashboard.articleblast.update',
    component: () => import(/* webpackChunkName: "article-list" */ '../views/Dashboard/ArticleBlast/NewEditor.vue'),
    meta: {
      label: 'New Article Email Blast [EDITOR]',
      layout: 'dashboard-flexible',
      useBackButton: true
    }
  },
  {
    path: '/dashboard/litbang-pemilu-2024',
    name: 'route.dashboard.litbang',
    component: () => import(/* webpackChunkName: "article-list" */ '../views/Dashboard/Litbang/pemilu2024.vue'),
    meta: {
      label: 'Quick Count Pemilu 2024',
      layout: 'dashboard-flexible'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
