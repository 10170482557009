import request from '@/utils/axios'

const urlGetTransform = function (baseurl, payload) {
  var url = baseurl
  url += '&page=' + payload.page
  url += '&start_date=' + payload.range.start
  url += '&end_date=' + payload.range.end
  url += '&filter_target=' + payload.filter.target
  url += '&filter_sort=' + payload.filter.sort
  return url
}

export default {
  createInit: ({ commit }) => {
    commit('SET_INTERNAL_USER_STATE', true)
    commit('SET_USER_STATUS_STATE', true)
  },
  getStatus: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit('SET_USER_STATUS_STATE', true)
      // commit('SET_USER_STATUS', { role: '' })
      request().get('/v1/internal-user/user-status')
        .then((result) => {
        // console.log(result)
          commit('SET_USER_STATUS', result.data)
          commit('SET_USER_STATUS_STATE', false)
          resolve(result.data)
        })
        .catch((err) => {
          console.log(err)
          commit('SET_USER_STATUS', { role: '' })
          commit('SET_USER_STATUS_STATE', false)
          reject(err)
        })
    })
  },
  getUsers: ({ commit }, { data }) => {
    return new Promise((resolve, reject) => {
      commit('SET_INTERNAL_USER_STATE', false)
      var url = urlGetTransform('/v1/internal-user?limit=10', data)
      request().get(url)
        .then((result) => {
          commit('SET_INTERNAL_USERS', result.data)
          resolve(result.data)
          commit('SET_INTERNAL_USER_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_INTERNAL_USER_STATE', true)
        })
    })
  },
  findUsers: ({ commit }, { data }) => {
    return new Promise((resolve, reject) => {
      commit('SET_INTERNAL_USER_STATE', false)
      request().post('/v1/internal-user/find', data)
        .then((result) => {
          commit('SET_INTERNAL_USERS', result.data)
          resolve(result.data)
          commit('SET_INTERNAL_USER_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_INTERNAL_USER_STATE', true)
        })
    })
  },
  getUserById: ({ commit }, { id }) => {
    return new Promise((resolve, reject) => {
      commit('SET_INTERNAL_USER_STATE', false)
      request().get('/v1/internal-user/show/' + id)
        .then((result) => {
          commit('SET_INTERNAL_USER', result.data)
          resolve(result.data)
          commit('SET_INTERNAL_USER_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_INTERNAL_USER_STATE', true)
        })
    })
  },
  addInternalUser: ({ commit }, { form }) => {
    return new Promise((resolve, reject) => {
      commit('SET_INTERNAL_USER_STATE', false)
      request().post('/v1/internal-user/create', form)
        .then((result) => {
          // commit('SET_INTERNAL_USERS', result.data)
          resolve(result.data)
          commit('SET_INTERNAL_USER_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_INTERNAL_USER_STATE', true)
        })
    })
  },
  updateInternalUser: ({ commit }, { form }) => {
    return new Promise((resolve, reject) => {
      commit('SET_INTERNAL_USER_STATE', false)
      request().put('/v1/internal-user/update/' + form.id, form)
        .then((result) => {
          // commit('SET_INTERNAL_USERS', result.data)
          resolve(result.data)
          commit('SET_INTERNAL_USER_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_INTERNAL_USER_STATE', true)
        })
    })
  }
}
