const SET_MARKET_INDEXES = (state, payload) => {
  state.marketDatas = payload
}
const SET_STATE = (state, payload) => {
  state.ready = payload
}

export default {
  SET_MARKET_INDEXES,
  SET_STATE
}
