import request from '@/utils/axios'

export default {
  getVisitoStatistic: ({ commit }, { filter }) => {
    return new Promise((resolve, reject) => {
      commit('SET_VISITOR_STATE', false)
      var url = '/v1/statistic/article/visitor'
      // if (filter.start_date !== undefined && filter.end_date !== undefined) {
      //   url += '?start_date=' + filter.start_date + '&end_date=' + filter.end_date
      // }
      if (filter.type !== undefined) {
        url += '?filter_type=' + filter.type
      }
      request().get(url)
        .then((result) => {
          commit('SET_VISITOR_DATA', result.data)
          resolve(result.data)
          commit('SET_VISITOR_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_VISITOR_STATE', true)
        })
    })
  }
}
