import mutation from './mutation'
import action from './action'
import getter from './getter'
export default {
  namespaced: true,
  state: {
    clients: [],
    client: {
      id: '',
      fullname: '',
      email: '',
      phone: '',
      gender: '',
      alamat: '',
      role: ''
    },
    ready: false
  },
  getters: getter,
  mutations: mutation,
  actions: action,
  modules: {}
}
