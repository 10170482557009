const SET_ARTICLES = (state, payload) => {
  state.articles = payload
}
const SET_ARTICLE = (state, payload) => {
  state.article = payload.data
  // console.log(state.article.first_content)
}
const SET_ARTICLE_STATE = (state, payload) => {
  state.ready = payload
}
const SET_THUMBNAIL = (state, payload) => {
  state.article.thumbnail = payload
}

export default {
  SET_ARTICLE,
  SET_ARTICLES,
  SET_ARTICLE_STATE,
  SET_THUMBNAIL
}
