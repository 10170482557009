const SET_INTERNAL_USERS = (state, payload) => {
  state.internal_users = payload
}
const SET_INTERNAL_USER = (state, payload) => {
  state.internal_user = payload.data
}
const SET_INTERNAL_USER_STATE = (state, payload) => {
  state.ready = payload
}
const SET_USER_STATUS = (state, payload) => {
  state.status = payload.data
}
const SET_USER_STATUS_STATE = (state, payload) => {
  state.statusState = payload
}

export default {
  SET_INTERNAL_USER,
  SET_INTERNAL_USERS,
  SET_USER_STATUS,
  SET_USER_STATUS_STATE,
  SET_INTERNAL_USER_STATE
}
