import Vue from 'vue'
import Vuex from 'vuex'
import internalUserModules from './internalUser'
import clientModules from './client'
import categoryModules from './category'
import articleModules from './article'
import userStatisticsModules from './statistic/user'
import articleStatisticsModules from './statistic/article'
import articleSliderModules from './slider'
import articleGroupModules from './articleGroup'
import articleVisitorModules from './statistic/articleVisitor'
import marketIndexModules from './marketIndex'
import translatedArticleModules from './translatedArticle'
import articleBlastModules from './articleBlast'
import newsletterModules from './newsletter'
import userModules from './user'
import litbangModules from './litbang'
import relatedTagModules from './relatedTag'
import researchTagModules from './researchTag'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    category: categoryModules,
    internalUser: internalUserModules,
    client: clientModules,
    article: articleModules,
    userStatistics: userStatisticsModules,
    articleStatistics: articleStatisticsModules,
    articleSlider: articleSliderModules,
    articleGroup: articleGroupModules,
    articleVisitor: articleVisitorModules,
    user: userModules,
    marketIndex: marketIndexModules,
    newsletter: newsletterModules,
    translatedArticle: translatedArticleModules,
    articleBlast: articleBlastModules,
    litbang: litbangModules,
    relatedTag: relatedTagModules,
    researchTag: researchTagModules
  }
})
