import mutation from './mutation'
import action from './action'
import getter from './getter'
export default {
  namespaced: true,
  state: {
    articles: [],
    article: {
      article_title: '',
      thumbnail: '',
      publish_date: '',
      article_category_id: '',
      first_summary: '',
      second_summary: '',
      third_summary: '',
      first_content: '',
      second_content: ''
    },
    ready: false
  },
  getters: getter,
  mutations: mutation,
  actions: action,
  modules: {}
}
