<template>
  <div
    v-if="active"
    class="origin-top-right absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-2" :style="styleItem"
  >
    <slot />
  </div>
</template>

<script>
export default {
  inject: ['sharedState'],
  computed: {
    active () {
      return this.sharedState.active
    }
  },
  props: {
    styleItem: {
      type: String,
      default: ''
    }
  },
  methods: {
    onClickOutside () {
      this.$emit('click-outside')
    }
  }
}
</script>
