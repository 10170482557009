import mutation from './mutation'
import action from './action'
import getter from './getter'
export default {
  namespaced: true,
  state: {
    tags: [],
    deletedTags: [],
    tag: {
      id: '',
      tag_name: ''
    },
    ready: false
  },
  getters: getter,
  mutations: mutation,
  actions: action,
  modules: {}
}
