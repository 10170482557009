import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VCalendar from 'v-calendar'
import vSelect from 'vue-select'
import VModal from 'vue-js-modal'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import moment from 'moment-timezone'

import '@/assets/css/index.css'
import '@/assets/css/tailwind.css'
import 'vue-select/dist/vue-select.css'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

import InputDropdownCaret from '@/components/Inputs/InputDropdownCaret.vue'

import BlankLayout from './layouts/BlankLayout.vue'
import DashboardLayout from './layouts/DashboardLayout.vue'
import DashbooardFlexibleLayout from './layouts/DashboardFlexibleLayout.vue'

Vue.use(VCalendar)
Vue.use(PerfectScrollbar)
Vue.use(VModal)

moment.tz.setDefault('Asia/Jakarta')

Vue.prototype.moment = moment

vSelect.props.components.default = () => ({ OpenIndicator: InputDropdownCaret })
Vue.component('v-select', vSelect)

Vue.component('blank-layout', BlankLayout)
Vue.component('dashboard-layout', DashboardLayout)
Vue.component('dashboard-flexible-layout', DashbooardFlexibleLayout)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
