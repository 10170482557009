const SET_RECORDS = (state, payload) => {
  state.records = payload
}
const SET_FETCH_STATE = (state, payload) => {
  state.fetching = payload
}
const SET_READY_STATE = (state, payload) => {
  state.ready = payload
}
const SET_UPDATE_STATE = (state, payload) => {
  state.updating = payload
}
export default {
  SET_RECORDS,
  SET_FETCH_STATE,
  SET_UPDATE_STATE,
  SET_READY_STATE
}
