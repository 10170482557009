import mutation from './mutation'
import action from './action'
import getter from './getter'
export default {
  namespaced: true,
  state: {
    newsletter: null,
    newsletterReady: false,
    newsletters: [],
    newslettersReady: false,
    marketDatas: [],
    targetedArticles: [],
    targetArticlesReady: false,
    ready: false,
    updatingNewsletter: false,
    previewReady: false,
    newsletterPreview: ''
  },
  getters: getter,
  mutations: mutation,
  actions: action,
  modules: {}
}
