import request from '@/utils/axios'

const urlGetTransform = function (baseurl, payload) {
  var url = baseurl
  url += '&page=' + payload.page
  url += '&start_date=' + payload.range.start
  url += '&end_date=' + payload.range.end
  url += '&filter_target=' + payload.filter.target
  url += '&filter_sort=' + payload.filter.sort
  return url
}

export default {
  createInit: ({ commit }) => {
    commit('SET_CLIENT_STATE', true)
  },
  getUsers: ({ commit }, { data }) => {
    return new Promise((resolve, reject) => {
      commit('SET_CLIENT_STATE', false)
      var url = urlGetTransform('/v1/client?limit=10', data)
      request().get(url)
        .then((result) => {
          commit('SET_CLIENTS', result.data)
          resolve(result.data)
          commit('SET_CLIENT_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_CLIENT_STATE', true)
        })
    })
  },
  findUsers: ({ commit }, { data }) => {
    return new Promise((resolve, reject) => {
      commit('SET_CLIENT_STATE', false)
      request().post('/v1/client/find', data)
        .then((result) => {
          commit('SET_CLIENTS', result.data)
          resolve(result.data)
          commit('SET_CLIENT_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_CLIENT_STATE', true)
        })
    })
  },
  getUserById: ({ commit }, { id }) => {
    return new Promise((resolve, reject) => {
      commit('SET_CLIENT_STATE', false)
      request().get('/v1/client/show/' + id)
        .then((result) => {
          commit('SET_CLIENT', result.data)
          resolve(result.data)
          commit('SET_CLIENT_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_CLIENT_STATE', true)
        })
    })
  },
  addInternalUser: ({ commit }, { form }) => {
    return new Promise((resolve, reject) => {
      commit('SET_CLIENT_STATE', false)
      request().post('/v1/client/create', form)
        .then((result) => {
          // commit('SET_CLIENTS', result.data)
          resolve(result.data)
          commit('SET_CLIENT_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_CLIENT_STATE', true)
        })
    })
  },
  updateInternalUser: ({ commit }, { form }) => {
    return new Promise((resolve, reject) => {
      commit('SET_CLIENT_STATE', false)
      request().put('/v1/client/update/' + form.id, form)
        .then((result) => {
          // commit('SET_CLIENTS', result.data)
          resolve(result.data)
          commit('SET_CLIENT_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_CLIENT_STATE', true)
        })
    })
  }
}
