import request from '@/utils/axios'

// { data: { range: this.fetchParam.range, page: this.fetchParam.currentPage, filter: this.fetchParam.filter } }
const urlGetTransform = function (baseurl, payload) {
  var url = baseurl
  if (payload !== null) {
    url += '&page=' + payload.page
    url += '&start_date=' + payload.range.start
    url += '&end_date=' + payload.range.end
    url += '&filter_target=' + payload.filter.target
    url += '&filter_sort=' + payload.filter.sort
    url += '&query=' + payload.query
  }
  return url
}

export default {
  createInit: ({ commit }) => {
    commit('SET_ARTICLE_STATE', true)
  },
  clearArticleList: ({ commit }) => {
    commit('SET_ARTICLES', [])
  },
  getArticles: ({ commit }, { data }) => {
    return new Promise((resolve, reject) => {
      commit('SET_ARTICLE_STATE', false)
      var url = urlGetTransform('/v1/article?limit=10', data)
      request().get(url)
        .then((result) => {
          commit('SET_ARTICLES', result.data)
          resolve(result.data)
          commit('SET_ARTICLE_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_ARTICLE_STATE', true)
        })
    })
  },
  findArticles: ({ commit }, { data }) => {
    return new Promise((resolve, reject) => {
      commit('SET_ARTICLE_STATE', false)
      request().post('/v1/article/find', data)
        .then((result) => {
          commit('SET_ARTICLES', result.data)
          resolve(result.data)
          commit('SET_ARTICLE_STATE', true)
        })
        .catch((err) => {
          // console.log(err)
          reject(err)
          commit('SET_ARTICLE_STATE', true)
        })
    })
  },
  getArticleById: ({ commit }, { id }) => {
    return new Promise((resolve, reject) => {
      commit('SET_ARTICLE_STATE', false)
      request().get('/v1/article/show/' + id)
        .then((result) => {
          commit('SET_ARTICLE', result.data)
          resolve(result.data)
          commit('SET_ARTICLE_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_ARTICLE_STATE', true)
        })
    })
  },
  addArticle: ({ commit }, { form }) => {
    return new Promise((resolve, reject) => {
      commit('SET_ARTICLE_STATE', false)
      request().post('/v1/article/create', form)
        .then((result) => {
          resolve(result.data)
          commit('SET_ARTICLE_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_ARTICLE_STATE', true)
        })
    })
  },
  updateArticle: ({ commit }, { form }) => {
    return new Promise((resolve, reject) => {
      commit('SET_ARTICLE_STATE', false)
      var id = -1
      if (form instanceof FormData) {
        id = form.get('id')
      } else {
        id = form.id
      }
      request().put('/v1/article/update/' + id, form)
        .then((result) => {
          // commit('SET_CATEGOORIES', result.data)
          resolve(result.data)
          commit('SET_ARTICLE_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_ARTICLE_STATE', true)
        })
    })
  },
  updateThumbnail: ({ commit }, { form }) => {
    return new Promise((resolve, reject) => {
      commit('SET_ARTICLE_STATE', false)
      var id = form.get('id')
      request().put('/v1/article/update-thumbnail/' + id, form)
        .then((result) => {
          // commit('SET_CATEGOORIES', result.data)
          resolve(result.data)
          commit('SET_THUMBNAIL', result.data.data.thumbnail)
          commit('SET_ARTICLE_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_ARTICLE_STATE', true)
        })
    })
  },
  softDeleteArticle: ({ commit }, { articleId }) => {
    return new Promise((resolve, reject) => {
      request().delete('/v1/article/destroy/' + articleId)
        .then((result) => {
          // commit('SET_CATEGOORIES', result.data)
          resolve(result.data)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
        })
    })
  }
}
