const SET_TAGS = (state, payload) => {
  console.log(payload.data.data)
  if (Array.isArray(payload.data.data)) {
    state.tags = payload.data.data
  }
  // console.log('test')
  // console.log(payload)
}
const SET_DELETED_TAGS = (state, payload) => {
  console.log(payload.data.data)
  if (Array.isArray(payload.data.data)) {
    state.deletedTags = payload.data.data
  }
  // console.log('test')
  // console.log(payload)
}
const SET_TAG_STATE = (state, payload) => {
  state.ready = payload
}

export default {
  SET_TAG_STATE,
  SET_TAGS,
  SET_DELETED_TAGS
}
