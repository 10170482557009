import request from '@/utils/axios'

// { data: { range: this.fetchParam.range, page: this.fetchParam.currentPage, filter: this.fetchParam.filter } }
// const urlGetTransform = function (baseurl, payload) {
//   var url = baseurl
//   url += '&page=' + payload.page
//   url += '&start_date=' + payload.range.start
//   url += '&end_date=' + payload.range.end
//   url += '&filter_target=' + payload.filter.target
//   url += '&filter_sort=' + payload.filter.sort
//   return url
// }

export default {
  fetchRecord: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit('SET_FETCH_STATE', true)
      commit('SET_READY_STATE', false)
      // var url = urlGetTransform('/v1/market-index', data)
      request().get('/v2/litbang/internal/master/record-metadata/find/1')
        .then((result) => {
          // console.log(result.data)
          commit('SET_RECORDS', result.data.data.data)
          resolve(result.data)
          commit('SET_READY_STATE', true)
          commit('SET_FETCH_STATE', false)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_FETCH_STATE', false)
        })
    })
  },
  pushNewData: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      commit('SET_FETCH_STATE', true)
      commit('SET_READY_STATE', false)
      // var url = urlGetTransform('/v1/market-index', data)
      request().post('/v2/litbang/internal/master/record-metadata/update-values', data)
        .then((result) => {
          // console.log(result.data)
          commit('SET_RECORDS', result.data.data.data)
          resolve(result.data)
          commit('SET_READY_STATE', true)
          commit('SET_FETCH_STATE', false)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_FETCH_STATE', false)
        })
    })
  }
  // publishBlast: ({ commit }, id) => {
  //   return new Promise((resolve, reject) => {
  //     request().get('/v1/email-blast-new-article/blast/' + id)
  //       .then((result) => {
  //         resolve(result.data)
  //       })
  //       .catch((err) => {
  //         reject(err)
  //       })
  //   })
  // },
  // fetchBlastData: ({ commit }) => {
  //   return new Promise((resolve, reject) => {
  //     commit('SET_BLASTDATA_READY', false)
  //     // var url = urlGetTransform('/v1/market-index', data)
  //     request().get('/v1/email-blast-new-article/content-to-blast')
  //       .then((result) => {
  //         console.log(result.data)
  //         commit('SET_BLASTDATA', result.data.data)
  //         resolve(result.data)
  //         commit('SET_BLASTDATA_READY', true)
  //       })
  //       .catch((err) => {
  //       // console.log(err)
  //         reject(err)
  //         commit('SET_BLASTDATA_READY', true)
  //       })
  //   })
  // },
  // fetchBlastItem: ({ commit }, id) => {
  //   return new Promise((resolve, reject) => {
  //     commit('SET_BLASTITEM_READY', false)
  //     // var url = urlGetTransform('/v1/market-index', data)
  //     request().get('/v1/email-blast-new-article/item/' + id)
  //       .then((result) => {
  //         console.log(result.data)
  //         commit('SET_BLASTITEM', result.data.data)
  //         resolve(result.data)
  //         commit('SET_BLASTITEM_READY', true)
  //       })
  //       .catch((err) => {
  //       // console.log(err)
  //         reject(err)
  //         commit('SET_BLASTITEM_READY', true)
  //       })
  //   })
  // },
  // updateBlastItem: ({ commit }, { form }) => {
  //   return new Promise((resolve, reject) => {
  //     commit('SET_UPDATE_STATE', true)
  //     // var url = urlGetTransform('/v1/market-index', data)
  //     request().post('/v1/email-blast-new-article/update', form)
  //       .then((result) => {
  //         // commit('SET_NEWSLETTER', result.data)
  //         // console.log(result)
  //         resolve(result.data)
  //         commit('SET_UPDATE_STATE', false)
  //       })
  //       .catch((err) => {
  //       // console.log(err)
  //         reject(err)
  //         commit('SET_UPDATE_STATE', false)
  //       })
  //   })
  // }
}
