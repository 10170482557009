import request from '@/utils/axios'

// { data: { range: this.fetchParam.range, page: this.fetchParam.currentPage, filter: this.fetchParam.filter } }
// const urlGetTransform = function (baseurl, payload) {
//   var url = baseurl
//   url += '&page=' + payload.page
//   url += '&start_date=' + payload.range.start
//   url += '&end_date=' + payload.range.end
//   url += '&filter_target=' + payload.filter.target
//   url += '&filter_sort=' + payload.filter.sort
//   return url
// }

export default {
  createGroupInit: ({ commit }) => {
    commit('SET_STATE', true)
  },
  clearTargetArticles: ({ commit }) => {
    commit('SET_TARGET_ARTICLES', [])
  },
  getNewsLetterPreview: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit('SET_PREVIEW_STATE', false)
      // var url = urlGetTransform('/v1/market-index', data)
      request().get('/v1/newsletter-setup/preview')
        .then((result) => {
          // console.log(result.data)
          commit('SET_NEWSLETTER_PREVIEW', result.data.data)
          resolve(result.data)
          commit('SET_PREVIEW_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_PREVIEW_STATE', true)
        })
    })
  },
  getTargetedArticles: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit('SET_TARGET_ARTICLE_STATE', false)
      // var url = urlGetTransform('/v1/market-index', data)
      request().get('/v1/newsletter-setup/targeted-articles')
        .then((result) => {
          // console.log(result.data)
          commit('SET_TARGET_ARTICLES', result.data)
          resolve(result.data)
          commit('SET_TARGET_ARTICLE_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_TARGET_ARTICLE_STATE', true)
        })
    })
  },
  getNewsletterArticles: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit('SET_NEWSLETTER_READY', false)
      // var url = urlGetTransform('/v1/market-index', data)
      request().get('/v1/email-blast/this-month-article')
        .then((result) => {
          commit('SET_NEWSLETTER', result.data)
          resolve(result.data)
          commit('SET_NEWSLETTER_READY', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_NEWSLETTER_READY', true)
        })
    })
  },
  getCurrentNewsletter: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit('SET_NEWSLETTER_READY', false)
      // var url = urlGetTransform('/v1/market-index', data)
      request().get('/v1/newsletter-setup/current')
        .then((result) => {
          commit('SET_NEWSLETTER', result.data.data)
          resolve(result.data.data)
          commit('SET_NEWSLETTER_READY', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_NEWSLETTER_READY', true)
        })
    })
  },
  getNewsletterHistories: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit('SET_NEWSLETTERS_READY', false)
      // var url = urlGetTransform('/v1/market-index', data)
      request().get('/v1/newsletter-setup/histories')
        .then((result) => {
          // console.log(result.data.data)
          commit('SET_NEWSLETTERS', result.data.data.newsletters)
          resolve(result.data.data)
          commit('SET_NEWSLETTERS_READY', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_NEWSLETTERS_READY', true)
        })
    })
  },
  updateNewsletter: ({ commit }, { form }) => {
    return new Promise((resolve, reject) => {
      commit('SET_UPDATE_STATE', true)
      // var url = urlGetTransform('/v1/market-index', data)
      request().post('/v1/newsletter-setup/update', form)
        .then((result) => {
          // commit('SET_NEWSLETTER', result.data)
          // console.log(result)
          resolve(result.data)
          commit('SET_UPDATE_STATE', false)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_UPDATE_STATE', false)
        })
    })
  },
  addNewMarketIndex: ({ commit, dispatch }, { form }) => {
    return new Promise((resolve, reject) => {
      commit('SET_STATE', false)
      // var url = urlGetTransform('/v1/market-index', data)
      request().post('/v1/market-index/create', form)
        .then((result) => {
          // if(result.data)
          // commit('SET_MARKET_INDEXES', result.data)
          resolve(result.data)
          dispatch('getMarketIndexes')
          // commit('SET_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          console.log(err)
          commit('SET_STATE', true)
        })
    })
  },
  addNewMarketData: ({ commit, dispatch }, { form }) => {
    return new Promise((resolve, reject) => {
      commit('SET_STATE', false)
      // var url = urlGetTransform('/v1/market-index', data)
      request().post('/v1/market-index/market-data', form)
        .then((result) => {
          // if(result.data)
          // commit('SET_MARKET_INDEXES', result.data)
          resolve(result.data)
          dispatch('getMarketIndexes')
          // commit('SET_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          console.log(err)
          commit('SET_STATE', true)
        })
    })
  }
  // findGroups: ({ commit }, { data }) => {
  //   return new Promise((resolve, reject) => {
  //     commit('SET_STATE', false)
  //     request().post('/v1/article-group/find', data)
  //       .then((result) => {
  //         commit('SET_GROUPS', result.data)
  //         resolve(result.data)
  //         commit('SET_STATE', true)
  //       })
  //       .catch((err) => {
  //       // console.log(err)
  //         reject(err)
  //         commit('SET_STATE', true)
  //       })
  //   })
  // },
  // getGroupById: ({ commit }, { id }) => {
  //   return new Promise((resolve, reject) => {
  //     commit('SET_STATE', false)
  //     request().get('/v1/article-group/show/' + id)
  //       .then((result) => {
  //         commit('SET_ARTICLE', result.data)
  //         resolve(result.data)
  //         commit('SET_STATE', true)
  //       })
  //       .catch((err) => {
  //       // console.log(err)
  //         reject(err)
  //         commit('SET_STATE', true)
  //       })
  //   })
  // },
  // addGroup: ({ commit }, { form }) => {
  //   return new Promise((resolve, reject) => {
  //     commit('SET_STATE', false)
  //     request().post('/v1/article-group/create', form)
  //       .then((result) => {
  //         resolve(result.data)
  //         commit('SET_STATE', true)
  //       })
  //       .catch((err) => {
  //       // console.log(err)
  //         reject(err)
  //         commit('SET_STATE', true)
  //       })
  //   })
  // },
  // updateGroup: ({ commit }, { form }) => {
  //   return new Promise((resolve, reject) => {
  //     commit('SET_STATE', false)
  //     request().put('/v1/article-group/update/' + form.id, form)
  //       .then((result) => {
  //         // commit('SET_CATEGOORIES', result.data)
  //         resolve(result.data)
  //         commit('SET_STATE', true)
  //       })
  //       .catch((err) => {
  //       // console.log(err)
  //         reject(err)
  //         commit('SET_STATE', true)
  //       })
  //   })
  // }
}
