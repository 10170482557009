
const GET_TRANSLATED_ARTICLE = state => {
  return state.translatedArticle
}

const TRANSLATED_ARTICLE_READY = state => {
  return state.ready
}

export default {
  TRANSLATED_ARTICLE_READY,
  GET_TRANSLATED_ARTICLE
}
