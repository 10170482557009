const SET_NEWSLETTER = (state, payload) => {
  state.newsletter = payload
}
const SET_NEWSLETTERS = (state, payload) => {
  state.newsletters = payload
}
const SET_TARGET_ARTICLES = (state, payload) => {
  state.targetedArticles = payload.data
}
const SET_NEWSLETTER_PREVIEW = (state, payload) => {
  state.newsletterPreview = payload.view
}
const SET_NEWSLETTER_READY = (state, payload) => {
  state.newsletterReady = payload
}
const SET_NEWSLETTERS_READY = (state, payload) => {
  state.newslettersReady = payload
}
const SET_UPDATE_STATE = (state, payload) => {
  state.updatingNewsletter = payload
}
const SET_PREVIEW_STATE = (state, payload) => {
  state.previewReady = payload
}
const SET_TARGET_ARTICLE_STATE = (state, payload) => {
  state.targetArticlesReady = payload
}

export default {
  SET_NEWSLETTER,
  SET_NEWSLETTER_READY,
  SET_UPDATE_STATE,
  SET_NEWSLETTERS,
  SET_NEWSLETTERS_READY,
  SET_TARGET_ARTICLE_STATE,
  SET_TARGET_ARTICLES,
  SET_NEWSLETTER_PREVIEW,
  SET_PREVIEW_STATE
}
