const GET_TOTAL_NEW_USER = state => {
  return state.totalNewUser
}

const TOTAL_NEW_USER_READY = state => {
  return state.totalNewUserState
}

const GET_TOTAL_ALL_USER = state => {
  return state.totalAllUser
}

const TOTAL_ALL_USER_READY = state => {
  return state.totalAllUserState
}

export default {
  GET_TOTAL_NEW_USER,
  TOTAL_NEW_USER_READY,
  GET_TOTAL_ALL_USER,
  TOTAL_ALL_USER_READY
}
