const GET_NEWSLETTERS = state => {
  var data = []
  if (state.newsletters !== undefined && state.newsletters !== null) {
    state.newsletters.forEach(val => {
      data.push(val)
    })
  }
  // console.log('data')
  // console.log(data)
  return data
}
const GET_NEWSLETTER = state => {
  return state.newsletter
}
const GET_NEWSLETTER_PREVIEW = state => {
  return state.newsletterPreview
}
const GET_PREVIEW_READY = state => {
  return state.previewReady
}
const GET_NEWSLETTER_ARTICLES = state => {
  return state.targetedArticles
}
const TARGET_ARTICLE_STATE = state => {
  return state.targetArticlesReady
}

const NEWSLETTER_READY = state => {
  return state.newsletterReady
}
const GET_UPDATE_STATE = state => {
  return state.updatingNewsletter
}
const NEWSLETTERS_READY = state => {
  return state.newslettersReady
}

export default {
  GET_NEWSLETTERS,
  NEWSLETTERS_READY,
  GET_NEWSLETTER,
  NEWSLETTER_READY,
  GET_UPDATE_STATE,
  GET_NEWSLETTER_ARTICLES,
  TARGET_ARTICLE_STATE,
  GET_NEWSLETTER_PREVIEW,
  GET_PREVIEW_READY
}
