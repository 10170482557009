import request from '@/utils/axios'

// { data: { range: this.fetchParam.range, page: this.fetchParam.currentPage, filter: this.fetchParam.filter } }
const urlGetTransform = function (baseurl, payload) {
  var url = baseurl
  url += '&page=' + payload.page
  url += '&start_date=' + payload.range.start
  url += '&end_date=' + payload.range.end
  url += '&filter_target=' + payload.filter.target
  url += '&filter_sort=' + payload.filter.sort
  return url
}

export default {
  createGroupInit: ({ commit }) => {
    commit('SET_ARTICLE_GROUP_STATE', true)
  },
  getGroups: ({ commit }, { data }) => {
    return new Promise((resolve, reject) => {
      commit('SET_ARTICLE_GROUP_STATE', false)
      var url = urlGetTransform('/v1/article-group?limit=10', data)
      request().get(url)
        .then((result) => {
          commit('SET_ARTICLE_GROUPS', result.data)
          resolve(result.data)
          commit('SET_ARTICLE_GROUP_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_ARTICLE_GROUP_STATE', true)
        })
    })
  },
  findGroups: ({ commit }, { data }) => {
    return new Promise((resolve, reject) => {
      commit('SET_ARTICLE_GROUP_STATE', false)
      request().post('/v1/article-group/find', data)
        .then((result) => {
          commit('SET_ARTICLE_GROUPS', result.data)
          resolve(result.data)
          commit('SET_ARTICLE_GROUP_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_ARTICLE_GROUP_STATE', true)
        })
    })
  },
  getGroupById: ({ commit }, { id }) => {
    return new Promise((resolve, reject) => {
      commit('SET_ARTICLE_GROUP_STATE', false)
      request().get('/v1/article-group/show/' + id)
        .then((result) => {
          commit('SET_ARTICLE', result.data)
          resolve(result.data)
          commit('SET_ARTICLE_GROUP_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_ARTICLE_GROUP_STATE', true)
        })
    })
  },
  addGroup: ({ commit }, { form }) => {
    return new Promise((resolve, reject) => {
      commit('SET_ARTICLE_GROUP_STATE', false)
      request().post('/v1/article-group/create', form)
        .then((result) => {
          resolve(result.data)
          commit('SET_ARTICLE_GROUP_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_ARTICLE_GROUP_STATE', true)
        })
    })
  },
  updateGroup: ({ commit }, { form }) => {
    return new Promise((resolve, reject) => {
      commit('SET_ARTICLE_GROUP_STATE', false)
      request().put('/v1/article-group/update/' + form.id, form)
        .then((result) => {
          // commit('SET_CATEGOORIES', result.data)
          resolve(result.data)
          commit('SET_ARTICLE_GROUP_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_ARTICLE_GROUP_STATE', true)
        })
    })
  }
}
