<template>
  <div
    class="relative cursor-pointer"
    v-click-outside="handleClickOutsideTogglerLayer"
  >
    <div @click="toggle()">
      <slot name="toggler"></slot>
    </div>
    <slot />
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
  directives: {
    ClickOutside
  },
  provide () {
    return {
      holdOnClick: this.holdOnClick,
      sharedState: this.sharedState
    }
  },
  props: {
    holdOnClick: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      sharedState: {
        active: false
      }
    }
  },
  methods: {
    handleClickOutsideTogglerLayer () {
      if (this.holdOnClick) {
        return
      }
      this.hide()
    },
    toggle () {
      this.sharedState.active = !this.sharedState.active
    },
    hide () {
      this.sharedState.active = false
    }
  }
}
</script>
