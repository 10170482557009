<template>
  <div class="container px-8 mt-12 flex justify-between items-center">
    <div class="flex items-center justify-between">
      <div>
        <h1 v-if="!isuseBackButton" class="font-headline-6 text-headline-6">
          {{ pageTitle }}
        </h1>
        <button
          v-if="isuseBackButton"
          class="font-headline-6 text-headline-6"
          @click="$router.go(-1)"
        >
          <div class="block">
            <svg
              class="inline-block align-middle"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 6.99997H3.83L8.71 2.11997C9.1 1.72997 9.1 1.08997 8.71 0.699971C8.32 0.309971 7.69 0.309971 7.3 0.699971L0.709996 7.28997C0.319996 7.67997 0.319996 8.30997 0.709996 8.69997L7.3 15.29C7.69 15.68 8.32 15.68 8.71 15.29C9.1 14.9 9.1 14.27 8.71 13.88L3.83 8.99997H15C15.55 8.99997 16 8.54997 16 7.99997C16 7.44997 15.55 6.99997 15 6.99997Z"
                fill="black"
              />
            </svg>
            <span class="inline-block align-middle px-3">
              Back
            </span>
          </div>
        </button>
      </div>
    </div>
    <div class="items-center flex">
      <div class="flex flex-col">
        <ButtonDropdown>
          <div class="font-button text-button text-gray">
            <div class="inline-block w-3/4 align-middle">Hi, there</div>
            <div class="inline-block w-1/4 align-middle">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.71005 11.71L11.3001 14.3C11.6901 14.69 12.3201 14.69 12.7101 14.3L15.3001 11.71C15.9301 11.08 15.4801 10 14.5901 10H9.41005C8.52005 10 8.08005 11.08 8.71005 11.71Z"
                  fill="#9E9E9E"
                />
              </svg>
            </div>
          </div>
          <template v-slot:content>
            <MenuDropdownItem>
                <router-link tag="div" :to="{ name: 'route.dashboard.profile' }" class="py-3">
                <svg
                  class="inline align-middle"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.9847 15.3462C8.11707 15.3462 4.81421 15.931 4.81421 18.2729C4.81421 20.6148 8.09611 21.2205 11.9847 21.2205C15.8523 21.2205 19.1542 20.6348 19.1542 18.2938C19.1542 15.9529 15.8733 15.3462 11.9847 15.3462Z"
                    stroke="#130F26"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.9846 12.0059C14.5227 12.0059 16.5799 9.94779 16.5799 7.40969C16.5799 4.8716 14.5227 2.81445 11.9846 2.81445C9.44655 2.81445 7.38845 4.8716 7.38845 7.40969C7.37988 9.93922 9.42369 11.9973 11.9523 12.0059H11.9846Z"
                    stroke="#130F26"
                    stroke-width="1.42857"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span class="inline pl-4 align-middle">
                  Profile
                </span>
              </router-link>
            </MenuDropdownItem>
            <MenuDropdownItem>
              <router-link tag="div" @click.native="logout()" to="/#" replace class="py-3">
                <svg
                  class="inline align-middle"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.016 7.38948V6.45648C15.016 4.42148 13.366 2.77148 11.331 2.77148H6.45597C4.42197 2.77148 2.77197 4.42148 2.77197 6.45648V17.5865C2.77197 19.6215 4.42197 21.2715 6.45597 21.2715H11.341C13.37 21.2715 15.016 19.6265 15.016 17.5975V16.6545"
                    stroke="#130F26"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.8096 12.0215H9.76855"
                    stroke="#130F26"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.8811 9.10645L21.8091 12.0214L18.8811 14.9374"
                    stroke="#130F26"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <span class="inline pl-4 align-middle">
                  Logout
                </span>
              </router-link>
            </MenuDropdownItem>
          </template>
        </ButtonDropdown>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonDropdown from '@/components/Buttons/ButtonDropdown.vue'
import MenuDropdownItem from '@/components/Menu/MenuDropdownItem.vue'

export default {
  components: {
    ButtonDropdown,
    MenuDropdownItem
  },
  computed: {
    pageTitle () {
      return this.$route.meta.label || 'Dashboard'
    },
    isuseBackButton () {
      return this.$route.meta.useBackButton || false
    }
  },
  methods: {
    logout () {
      localStorage.removeItem('access_token')
      this.$router.replace({ name: 'route.root' })
    }
  }
}
</script>
