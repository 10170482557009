const SET_ARTICLE_GROUPS = (state, payload) => {
  state.articleGroups = payload
}
const SET_ARTICLE = (state, payload) => {
  state.articleGroup = payload.data
}
const SET_ARTICLE_GROUP_STATE = (state, payload) => {
  state.ready = payload
}

export default {
  SET_ARTICLE,
  SET_ARTICLE_GROUPS,
  SET_ARTICLE_GROUP_STATE
}
