const GET_MARKET_INDEXES = state => {
  var data = []
  if (state.marketDatas.data !== undefined && state.marketDatas.data !== null) {
    state.marketDatas.data.forEach(val => {
      data.push(val)
    })
  }
  // console.log('data')
  // console.log(data)
  return data
}

const MARKET_INDEX_READY = state => {
  return state.ready
}

export default {
  GET_MARKET_INDEXES,
  MARKET_INDEX_READY
}
