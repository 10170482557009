import request from '@/utils/axios'

export default {
  getTotalNewUser: ({ commit }, { filter }) => {
    return new Promise((resolve, reject) => {
      commit('SET_TOTAL_NEW_USER_STATE', false)
      var url = '/v1/statistic/users/new-account'
      if (filter.start_date !== undefined && filter.end_date !== undefined) {
        url += '?start_date=' + filter.start_date + '&end_date=' + filter.end_date
      }
      request().get(url)
        .then((result) => {
          commit('SET_TOTAL_NEW_USER', result.data)
          resolve(result.data)
          commit('SET_TOTAL_NEW_USER_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_TOTAL_NEW_USER_STATE', true)
        })
    })
  },
  getTotalAllUser: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit('SET_TOTAL_ALL_USER_STATE', false)
      var url = '/v1/statistic/users/all'
      request().get(url)
        .then((result) => {
          commit('SET_TOTAL_ALL_USER', result.data)
          resolve(result.data)
          commit('SET_TOTAL_ALL_USER_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_TOTAL_ALL_USER_STATE', true)
        })
    })
  }
}
