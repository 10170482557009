const GET_VISITOR_DATA = state => {
  return state.tracker
}

const GET_VISITOR_STATE = state => {
  return state.state
}

export default {
  GET_VISITOR_DATA,
  GET_VISITOR_STATE
}
