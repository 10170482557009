const GET_TAGS = state => {
  var data = []
  if (state.tags !== undefined) {
    state.tags.forEach(val => {
      data.push(val)
    })
  }
  // console.log('data')
  // console.log(data)
  return data
}
const GET_DELETED_TAGS = state => {
  var data = []
  if (state.deletedTags !== undefined) {
    state.deletedTags.forEach(val => {
      data.push(val)
    })
  }
  // console.log('data')
  // console.log(data)
  return data
}

const TAG_READY = state => {
  return state.ready
}

export default {
  GET_TAGS,
  TAG_READY,
  GET_DELETED_TAGS
}
