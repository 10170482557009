const GET_CLIENTS = state => {
  var data = []
  if (state.clients.data !== undefined) {
    state.clients.data.forEach(val => {
      if (val.profile === undefined) {
        val.profile = 0
      }
      data.push(val)
    })
  }
  return data
}

const GET_CLIENT_TOTAL_PAGE = state => {
  return state.clients.total_page
}

const GET_CLIENT = state => {
  return state.client
}

const CLIENT_READY = state => {
  return state.ready
}

export default {
  GET_CLIENTS,
  CLIENT_READY,
  GET_CLIENT,
  GET_CLIENT_TOTAL_PAGE
}
