const GET_CATEGORIES = state => {
  var data = []
  if (state.categories.data !== undefined) {
    state.categories.data.forEach(val => {
      data.push(val)
    })
  }
  // console.log('data')
  // console.log(data)
  return data
}
const GET_CATEGORY_TOTAL_PAGE = state => {
  return state.categories.total_page
}
const SELECT_CATEGORY = state => {
  var data = []
  if (state.categories.data !== undefined) {
    state.categories.data.forEach(val => {
      data.push({ id: val.id, article_category_name: val.article_category_name })
    })
  }
  // console.log('data')
  // console.log(data)
  return data
}
const GET_CATEGORY = state => {
  return state.category
}

const CATEGORY_READY = state => {
  return state.ready
}

export default {
  GET_CATEGORIES,
  CATEGORY_READY,
  GET_CATEGORY,
  SELECT_CATEGORY,
  GET_CATEGORY_TOTAL_PAGE
}
