import mutation from './mutation'
import action from './action'
import getter from './getter'
export default {
  namespaced: true,
  state: {
    totalNewUser: {
      total: '',
      module: '',
      start_date: '',
      end_date: ''
    },
    totalAllUser: {
      total: '',
      module: '',
      start_date: '',
      end_date: ''
    },
    totalNewUserState: false,
    totalAllUserState: false
  },
  getters: getter,
  mutations: mutation,
  actions: action,
  modules: {}
}
