import request from '@/utils/axios'

export default {
  getProfile: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit('SET_PROFILE_STATE', false)
      var url = '/v1/user/profile'
      request().get(url)
        .then((result) => {
          commit('SET_PROFILE', result.data)
          resolve(result.data)
          commit('SET_PROFILE_STATE', true)
        })
        .catch((err) => {
        // console.log(err)
          reject(err)
          commit('SET_PROFILE_STATE', true)
        })
    })
  }
}
