const SET_ARTICLE_SLIDERS = (state, payload) => {
  state.articleSliders = payload
}
const SET_ARTICLE = (state, payload) => {
  state.articleSlider = payload.data
}
const SET_ARTICLE_SLIDER_STATE = (state, payload) => {
  state.ready = payload
}

export default {
  SET_ARTICLE,
  SET_ARTICLE_SLIDERS,
  SET_ARTICLE_SLIDER_STATE
}
