// total new user
const SET_TOTAL_NEW_USER = (state, payload) => {
  state.totalNewUser = payload.data
}
const SET_TOTAL_NEW_USER_STATE = (state, payload) => {
  state.totalNewUserState = payload
}
const SET_TOTAL_ALL_USER = (state, payload) => {
  state.totalAllUser = payload.data
}
const SET_TOTAL_ALL_USER_STATE = (state, payload) => {
  state.totalAllUserState = payload
}
// end of total new user

export default {
  SET_TOTAL_NEW_USER,
  SET_TOTAL_NEW_USER_STATE,
  SET_TOTAL_ALL_USER,
  SET_TOTAL_ALL_USER_STATE
}
