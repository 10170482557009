const SET_CLIENTS = (state, payload) => {
  state.clients = payload
}
const SET_CLIENT = (state, payload) => {
  state.client = payload.data
}
const SET_CLIENT_STATE = (state, payload) => {
  state.ready = payload
}

export default {
  SET_CLIENT,
  SET_CLIENTS,
  SET_CLIENT_STATE
}
