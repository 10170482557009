const GET_ARTICLE_SLIDERS = state => {
  var data = []
  if (state.articleSliders.data !== undefined && state.articleSliders.data !== null) {
    state.articleSliders.data.forEach(val => {
      data.push(val)
    })
  }
  // console.log('data')
  // console.log(data)
  return data
}
const GET_ARTICLE_SLIDER_TOTAL_PAGE = state => {
  return state.articleSliders.total_page
}
const SELECT_ARTICLE_SLIDER = state => {
  var data = []
  if (state.articleSliders.data !== undefined && state.articleSliders.data !== null) {
    state.articleSliders.data.forEach(val => {
      data.push({ id: val.id, slider_name: val.slider_name })
    })
  }
  // console.log('data')
  // console.log(data)
  return data
}
const GET_ARTICLE_SLIDER = state => {
  return state.articleSlider
}

const ARTICLE_SLIDER_READY = state => {
  return state.ready
}

export default {
  GET_ARTICLE_SLIDERS,
  ARTICLE_SLIDER_READY,
  GET_ARTICLE_SLIDER,
  SELECT_ARTICLE_SLIDER,
  GET_ARTICLE_SLIDER_TOTAL_PAGE
}
