import axios from 'axios'

const req = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true
})

req.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('access_token')
      window.location.assign('/')
      // console.log(error.response.data)
      // console.log(error.response.status)
      // console.log(error.response.headers)
    } else if (error.response && error.response.status === 403) {
      window.location.assign('/')
    }
    // console.log(error)
    // return Promise.reject(error)
  }
)

const request = () => {
  var token = localStorage.getItem('access_token')
  if (token !== null) {
    req.defaults.headers.common.Authorization = 'Bearer ' + localStorage.getItem('access_token')
  }
  return req
}

export default request
