const GET_RECORDS = state => {
  return state.records
}
const GET_FETCH = state => {
  return state.fetching
}
const GET_UPDATE = state => {
  return state.updating
}
const GET_READY = state => {
  return state.ready
}
export default {
  GET_RECORDS,
  GET_FETCH,
  GET_UPDATE,
  GET_READY
}
